<template>
  <vue-marquee-slider
    class="clients"
    id="marquee-slider"
    :space="200"
    :speed="20000"
    :width="100"
    :reverse="false"
    data-aos="fade-down"
  >
    <img
      loading="lazy"
      v-for="(client, clientIndex) in clients"
      :key="clientIndex"
      :src="requireAsset(client.logo)"
      alt=""
      class="clients__item"
    />
  </vue-marquee-slider>
</template>

<script>
export default {
  name: "Clients",
  props: {
    clients: Array,
  },
};
</script>

<style lang="scss" scoped>
.clients {
  width: 100dvw;
  &__item {
    width: 100px;
    height: auto;
    object-fit: contain;
    filter: grayscale(100%) brightness(0) invert(0%);
    -webkit-transition: 0.35s ease-in-out all;
    -moz-transition: 0.35s ease-in-out all;
    -ms-transition: 0.35s ease-in-out all;
    -o-transition: 0.35s ease-in-out all;
    transition: 0.35s ease-in-out all;
  }
}
</style>
