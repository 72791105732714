import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import VueMarqueeSlider from "vue-marquee-slider";
Vue.use(VueMarqueeSlider);

import AOS from "aos";
import "aos/dist/aos.css";

import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
const options = {
  inline: true,
  button: true,
  navbar: true,
  title: false,
  toolbar: false,
  tooltip: false,
  movable: true,
  zoomable: true,
  rotatable: false,
  scalable: true,
  transition: true,
  fullscreen: false,
  keyboard: false,
};
VueViewer.setDefaults(options);
Vue.use(VueViewer);

import literales from "@/assets/backend/literales.json";
import errorAsset from "@/assets/images/errorAsset.svg";
Vue.mixin({
  methods: {
    refreshAOS() {
      setTimeout(() => {
        AOS.refresh();
      }, 350);
    },
    getLiteral(cod, language) {
      return literales[cod][language];
    },
    resolveAsset(e) {
      var element;
      if (e.target == null || e.target == undefined) {
        element = e.path[0];
      } else {
        element = e.target;
      }

      setTimeout(() => {
        element.parentNode.classList.remove("skeleton");
      }, 175);
    },
    requireAsset(path) {
      var asset;
      try {
        asset = require("@/assets/images/" + path);
      } catch (error) {
        asset = errorAsset;
        console.log("%c" + "@/assets/images/" + path, "color:red");
      }
      return asset;
    },
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted() {
    AOS.init({
      offset: 25,
      delay: 0,
      duration: 350,
      easing: "ease",
      once: false,
      mirror: true,
      anchorPlacement: "top-bottom",
    });
  },
}).$mount("#app");
