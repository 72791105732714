<template>
  <TransitionGroup name="fade">
    <Loader key="Loader" v-if="Object.keys(web).length === 0" @web="setWeb" />
    <div
      key="App"
      v-else
      class="app"
      :class="{
        dark: $store.getters.getTheme == 'dark',
      }"
    >
      <Header />
      <Slider :aplicacion="web[$store.getters.getLanguage]" />
      <div class="wrapper">
        <Transition name="fade" mode="out-in">
          <RouterView :aplicacion="web[$store.getters.getLanguage]" />
        </Transition>
      </div>
      <Footer :aplicacion="web[$store.getters.getLanguage]" />
      <GoTo />
    </div>
  </TransitionGroup>
</template>

<script>
import Loader from "@/components/Loader.vue";
import Header from "@/components/Header.vue";
import Slider from "@/components/Slider.vue";
import Footer from "@/components/Footer.vue";
import GoTo from "@/components/GoTo.vue";

export default {
  name: "App",
  components: {
    Loader,
    Header,
    Slider,
    Footer,
    GoTo,
  },
  data() {
    return {
      web: new Object(),
    };
  },
  created() {
    window.addEventListener("resize", this.refreshAOS);
  },
  destroyed() {
    window.removeEventListener("resize", this.refreshAOS);
  },
  methods: {
    setWeb(web) {
      this.web = web;
    },
  },
};
</script>

<style lang="scss">
.app {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: white;
  -webkit-transition: 0.35s ease-in-out all;
  -moz-transition: 0.35s ease-in-out all;
  -ms-transition: 0.35s ease-in-out all;
  -o-transition: 0.35s ease-in-out all;
  transition: 0.35s ease-in-out all;
  &.dark {
    background-color: #1e1e1e;
    -webkit-transition: 0.35s ease-in-out all;
    -moz-transition: 0.35s ease-in-out all;
    -ms-transition: 0.35s ease-in-out all;
    -o-transition: 0.35s ease-in-out all;
    transition: 0.35s ease-in-out all;
    & .footer {
      background-color: #121212;
      -webkit-transition: 0.35s ease-in-out all;
      -moz-transition: 0.35s ease-in-out all;
      -ms-transition: 0.35s ease-in-out all;
      -o-transition: 0.35s ease-in-out all;
      transition: 0.35s ease-in-out all;
      &__bottom {
        &__copyright {
          color: white;
          -webkit-transition: 0.35s ease-in-out all;
          -moz-transition: 0.35s ease-in-out all;
          -ms-transition: 0.35s ease-in-out all;
          -o-transition: 0.35s ease-in-out all;
          transition: 0.35s ease-in-out all;
        }
      }
    }
    & .services {
      &__item {
        &__text {
          color: white;
          -webkit-transition: 0.35s ease-in-out all;
          -moz-transition: 0.35s ease-in-out all;
          -ms-transition: 0.35s ease-in-out all;
          -o-transition: 0.35s ease-in-out all;
          transition: 0.35s ease-in-out all;
        }
      }
    }
    & .clients {
      &__item {
        filter: grayscale(100%) brightness(0) invert(100%);
        -webkit-transition: 0.35s ease-in-out all;
        -moz-transition: 0.35s ease-in-out all;
        -ms-transition: 0.35s ease-in-out all;
        -o-transition: 0.35s ease-in-out all;
        transition: 0.35s ease-in-out all;
      }
    }
    & .projects {
      &__high {
        &__item {
          &__content {
            &__info {
              &__title {
                &__name {
                  color: white;
                  -webkit-transition: 0.35s ease-in-out all;
                  -moz-transition: 0.35s ease-in-out all;
                  -ms-transition: 0.35s ease-in-out all;
                  -o-transition: 0.35s ease-in-out all;
                  transition: 0.35s ease-in-out all;
                }
              }
              &__location {
                color: white;
                -webkit-transition: 0.35s ease-in-out all;
                -moz-transition: 0.35s ease-in-out all;
                -ms-transition: 0.35s ease-in-out all;
                -o-transition: 0.35s ease-in-out all;
                transition: 0.35s ease-in-out all;
              }
              &__text {
                color: white;
                -webkit-transition: 0.35s ease-in-out all;
                -moz-transition: 0.35s ease-in-out all;
                -ms-transition: 0.35s ease-in-out all;
                -o-transition: 0.35s ease-in-out all;
                transition: 0.35s ease-in-out all;
              }
            }
          }
        }
      }
      &__low {
        &__title {
          color: white;
          -webkit-transition: 0.35s ease-in-out all;
          -moz-transition: 0.35s ease-in-out all;
          -ms-transition: 0.35s ease-in-out all;
          -o-transition: 0.35s ease-in-out all;
          transition: 0.35s ease-in-out all;
        }
      }
    }
    & .project {
      &__title {
        color: white;
        -webkit-transition: 0.35s ease-in-out all;
        -moz-transition: 0.35s ease-in-out all;
        -ms-transition: 0.35s ease-in-out all;
        -o-transition: 0.35s ease-in-out all;
        transition: 0.35s ease-in-out all;
      }
    }
    & .about {
      &__contact {
        &__left {
          &__info {
            &__item {
              &__text {
                color: white;
                -webkit-transition: 0.35s ease-in-out all;
                -moz-transition: 0.35s ease-in-out all;
                -ms-transition: 0.35s ease-in-out all;
                -o-transition: 0.35s ease-in-out all;
                transition: 0.35s ease-in-out all;
              }
            }
          }
        }
        &__right {
          &__item {
            &__input {
              color: white;
              -webkit-transition: 0.35s ease-in-out all;
              -moz-transition: 0.35s ease-in-out all;
              -ms-transition: 0.35s ease-in-out all;
              -o-transition: 0.35s ease-in-out all;
              transition: 0.35s ease-in-out all;
            }
            &__textarea {
              color: white;
              -webkit-transition: 0.35s ease-in-out all;
              -moz-transition: 0.35s ease-in-out all;
              -ms-transition: 0.35s ease-in-out all;
              -o-transition: 0.35s ease-in-out all;
              transition: 0.35s ease-in-out all;
            }
          }
        }
      }
      &__staffs {
        &__item {
          &__telephone {
            &__text {
              color: white;
              -webkit-transition: 0.35s ease-in-out all;
              -moz-transition: 0.35s ease-in-out all;
              -ms-transition: 0.35s ease-in-out all;
              -o-transition: 0.35s ease-in-out all;
              transition: 0.35s ease-in-out all;
            }
          }
          &__mail {
            &__text {
              color: white;
              -webkit-transition: 0.35s ease-in-out all;
              -moz-transition: 0.35s ease-in-out all;
              -ms-transition: 0.35s ease-in-out all;
              -o-transition: 0.35s ease-in-out all;
              transition: 0.35s ease-in-out all;
            }
          }
        }
      }
    }
  }
}
</style>
