<template>
  <div ref="loader" class="loader">
    <video
      playsinline
      autoplay
      loop
      muted
      class="loader__image"
      @canplay="setCarga"
    >
      <source :src="requireAsset('Loader.webm')" type="video/webm" />
    </video>
  </div>
</template>

<script>
import DB from "@/assets/backend/database.json";
import Web from "@/assets/backend/classes/Web.js";
import Aplicacion from "@/assets/backend/classes/Aplicacion.js";
import Project from "@/assets/backend/classes/Project.js";
import Client from "@/assets/backend/classes/Client.js";
import Service from "@/assets/backend/classes/Service.js";
import Statistic from "@/assets/backend/classes/Statistic.js";
import Staff from "@/assets/backend/classes/Staff.js";

export default {
  name: "Loader",
  data() {
    return {
      loaderDuration: 2000,
    };
  },
  methods: {
    getLiteral(literales, language) {
      return literales.find((literal) => literal.idioma == language).texto;
    },
    setCarga() {
      var start = performance.now();

      this.$refs.loader.style.opacity = 1;

      const web = new Web();

      this.$store.commit(
        "setTheme",
        localStorage.getItem("mueblescercos_theme") ?? DB.themeDef
      );
      this.$store.commit(
        "setLanguage",
        localStorage.getItem("mueblescercos_language") ?? DB.languageDef
      );
      this.$store.commit("setLanguages", DB.languages);

      this.$store.getters.getLanguages.forEach((languageEl) => {
        const aplicacion = new Aplicacion();
        aplicacion.copyright = this.getLiteral(
          DB.copyright,
          languageEl
        ).replace("[Year]", new Date().getFullYear());
        DB.projects.sort((a, b) => a.order - b.order);
        DB.projects.forEach((projectEl) => {
          const project = new Project();
          project.cod = projectEl.cod;
          project.visibility = projectEl.visibility;
          project.name = this.getLiteral(projectEl.name, languageEl);
          project.stars = projectEl.stars;
          project.location = this.getLiteral(projectEl.location, languageEl);
          project.text = this.getLiteral(projectEl.text, languageEl);
          project.spaces = this.getLiteral(projectEl.spaces, languageEl);
          project.posterIndex = projectEl.posterIndex;
          project.miniatures = projectEl.miniatures;
          project.images = projectEl.images;
          aplicacion.projects.push(project);
        });
        DB.clients.forEach((clientEl) => {
          const client = new Client();
          client.name = this.getLiteral(clientEl.name, languageEl);
          client.logo = clientEl.logo;
          aplicacion.clients.push(client);
        });
        DB.services.forEach((serviceEl) => {
          const service = new Service();
          service.name = this.getLiteral(serviceEl.name, languageEl);
          service.text = this.getLiteral(serviceEl.text, languageEl);
          service.miniature = serviceEl.miniature;
          aplicacion.services.push(service);
        });
        DB.statistics.forEach((statisticEl) => {
          const statistic = new Statistic();
          statistic.title = this.getLiteral(statisticEl.title, languageEl);
          statistic.subtitle = this.getLiteral(
            statisticEl.subtitle,
            languageEl
          );
          aplicacion.statistics.push(statistic);
        });
        DB.staff.forEach((staffEl) => {
          const staff = new Staff();
          staff.name = this.getLiteral(staffEl.name, languageEl);
          staff.position = this.getLiteral(staffEl.position, languageEl);
          staff.telephone = staffEl.telephone;
          staff.mail = staffEl.mail;
          aplicacion.staffs.push(staff);
        });
        web[languageEl] = aplicacion;
      });

      var end = performance.now();

      var cargaTime = end - start;

      setTimeout(() => {
        console.log(web);
        this.$emit("web", web);
      }, this.loaderDuration + cargaTime);
    },
  },
};
</script>

<style lang="scss" scoped>
.loader {
  position: fixed;
  opacity: 0;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100dvh;
  padding: 10px;
  -webkit-transition: 0.35s ease-in-out opacity;
  -moz-transition: 0.35s ease-in-out opacity;
  -ms-transition: 0.35s ease-in-out opacity;
  -o-transition: 0.35s ease-in-out opacity;
  transition: 0.35s ease-in-out opacity;
  &__image {
    width: 100%;
    height: auto;
    max-width: 200px;
  }
}
</style>
