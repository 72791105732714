export default class Project {
  constructor() {
    this.visibility = new String();
    this.name = new String();
    this.stars = new String();
    this.location = new String();
    this.text = new String();
    this.spaces = new String();
    this.miniatures = new Array();
    this.images = new Array();
    this.posterIndex = new Number();
    this.getPoster = function (quality) {
      return quality == "hd"
        ? this.images[this.posterIndex]
        : this.miniatures[this.posterIndex];
    };
  }
}
