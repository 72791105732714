<template>
  <div ref="slider" class="slider skeleton" :class="$route.name">
    <img
      loading="lazy"
      :src="requireAsset('spinner.svg')"
      alt=""
      class="skeleton__loader"
    />
    <Transition
      v-if="$route.name == 'Home' || $route.name == 'Projects'"
      name="fade"
      mode="out-in"
    >
      <video
        :key="$route.name"
        playsinline
        autoplay
        loop
        muted
        class="slider__video"
        @canplay="resolveAsset($event)"
      >
        <source :src="requireAsset($route.name + '.webm')" type="video/webm" />
      </video>
    </Transition>
    <Transition v-else-if="$route.name == 'Project'" name="fade" mode="out-in">
      <img
        loading="lazy"
        :key="$route.name"
        @load="resolveAsset($event)"
        :src="
          requireAsset(
            aplicacion.getProject($route.params.project).getPoster('hd')
          )
        "
        alt=""
        class="slider__image"
      />
    </Transition>
    <Transition v-else-if="$route.name == 'About'" name="fade" mode="out-in">
      <img
        loading="lazy"
        :key="$route.name"
        @load="resolveAsset($event)"
        :src="requireAsset('About.webp')"
        alt=""
        class="slider__image"
      />
    </Transition>
    <div class="slider__dropdown">
      <img
        loading="lazy"
        @click="scrollToSliderEnd"
        :src="requireAsset('icons/dropdown.svg')"
        alt=""
        class="slider__dropdown__image noSkeleton"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Slider",
  props: {
    aplicacion: Object,
  },
  methods: {
    scrollToSliderEnd() {
      const headerHeight = 100;
      const slider = this.$refs.slider;
      const offsetTop = slider.offsetTop + slider.offsetHeight - headerHeight;

      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.slider {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100dvh;
  overflow: hidden;
  -webkit-transition: 0.35s ease-in-out all;
  -moz-transition: 0.35s ease-in-out all;
  -ms-transition: 0.35s ease-in-out all;
  -o-transition: 0.35s ease-in-out all;
  transition: 0.35s ease-in-out all;
  &__video {
    opacity: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-transition: 0.35s ease-in-out all;
    -moz-transition: 0.35s ease-in-out all;
    -ms-transition: 0.35s ease-in-out all;
    -o-transition: 0.35s ease-in-out all;
    transition: 0.35s ease-in-out all;
  }
  &__image {
    opacity: 1;
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
    -webkit-transition: 0.35s ease-in-out all;
    -moz-transition: 0.35s ease-in-out all;
    -ms-transition: 0.35s ease-in-out all;
    -o-transition: 0.35s ease-in-out all;
    transition: 0.35s ease-in-out all;
  }
  &__dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    bottom: 10px;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    background-color: $primary-color;
    -webkit-transition: 0.35s ease-in-out background-color;
    -moz-transition: 0.35s ease-in-out background-color;
    -ms-transition: 0.35s ease-in-out background-color;
    -o-transition: 0.35s ease-in-out background-color;
    transition: 0.35s ease-in-out background-color;
    &__image {
      width: 20px;
      height: 20px;
      object-fit: contain;
    }
    @media (hover: hover) {
      &:hover {
        background-color: rgb($primary-color, 0.65);
        -webkit-transition: 0.35s ease-in-out background-color;
        -moz-transition: 0.35s ease-in-out background-color;
        -ms-transition: 0.35s ease-in-out background-color;
        -o-transition: 0.35s ease-in-out background-color;
        transition: 0.35s ease-in-out background-color;
      }
    }
  }
  &.Projects {
    height: 75dvh;
    -webkit-transition: 0.35s ease-in-out all;
    -moz-transition: 0.35s ease-in-out all;
    -ms-transition: 0.35s ease-in-out all;
    -o-transition: 0.35s ease-in-out all;
    transition: 0.35s ease-in-out all;
  }
  &.Project {
    height: 50dvh;
    -webkit-transition: 0.35s ease-in-out all;
    -moz-transition: 0.35s ease-in-out all;
    -ms-transition: 0.35s ease-in-out all;
    -o-transition: 0.35s ease-in-out all;
    transition: 0.35s ease-in-out all;
  }
  &.About {
    height: 50dvh;
    -webkit-transition: 0.35s ease-in-out all;
    -moz-transition: 0.35s ease-in-out all;
    -ms-transition: 0.35s ease-in-out all;
    -o-transition: 0.35s ease-in-out all;
    transition: 0.35s ease-in-out all;
  }
}
</style>
