<template>
  <div class="statistics">
    <div
      v-for="(statistic, statisticIndex) in parsedStatistics"
      :key="statisticIndex"
      class="statistics__item"
      data-aos="fade-down"
      :data-aos-delay="statisticIndex * 50"
    >
      <Transition name="fade" mode="out-in">
        <span :key="statistic.title" class="statistics__item__title">
          {{ statistic.title }}
        </span>
      </Transition>
      <div class="statistics__item__subtitle">
        <Transition name="fade" mode="out-in">
          <span
            :key="statistic.title"
            class="statistics__item__subtitle__number"
          >
            {{ statistic.number }}
          </span>
        </Transition>
        <Transition name="fade" mode="out-in">
          <span :key="statistic.title" class="statistics__item__subtitle__text">
            {{ statistic.text }}
          </span>
        </Transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Statistics",
  props: {
    statistics: Array,
  },
  computed: {
    parsedStatistics() {
      return this.statistics.map(({ title, subtitle }) => {
        const [number, ...textParts] = subtitle.split(" ");
        return {
          title,
          number,
          text: textParts.join(" "),
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.statistics {
  display: grid;
  grid-template-columns: repeat(4, minmax(auto, 1fr));
  width: 100%;
  height: 100%;
  gap: 100px;
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__title {
      font-weight: 600;
      color: $primary-color;
    }
    &__subtitle {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;
      gap: 5px;
      &__number {
        font-size: 40px;
        font-weight: 500;
        color: $primary-color;
      }
      &__text {
        font-weight: 600;
        color: $primary-color;
      }
    }
  }
  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(3, minmax(auto, 1fr));
    @media screen and (max-width: 700px) {
      grid-template-columns: repeat(2, minmax(auto, 1fr));
      @media screen and (max-width: 500px) {
        grid-template-columns: repeat(1, minmax(auto, 1fr));
      }
    }
  }
}
</style>
