<template>
  <div class="footer">
    <div class="wrapper">
      <div class="footer__top">
        <div class="footer__top__logo">
          <img
            loading="lazy"
            :src="requireAsset('logo.webp')"
            alt=""
            class="footer__top__logo__image"
          />
        </div>
      </div>
      <div class="footer__bottom">
        <Transition name="fade" mode="out-in">
          <span :key="aplicacion.copyright" class="footer__bottom__copyright">
            {{ aplicacion.copyright }}
          </span>
        </Transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {
    aplicacion: Object,
  },
};
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  background-color: #f4f4f4;
  padding: 25px 0px;
  gap: 25px;
  -webkit-transition: 0.35s ease-in-out all;
  -moz-transition: 0.35s ease-in-out all;
  -ms-transition: 0.35s ease-in-out all;
  -o-transition: 0.35s ease-in-out all;
  transition: 0.35s ease-in-out all;
  &__top {
    &__logo {
      &__image {
        width: 55px;
        height: 55px;
        object-fit: contain;
      }
    }
  }
  &__bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    &__copyright {
      color: black;
      font-size: 14px;
      text-align: center;
      -webkit-transition: 0.35s ease-in-out all;
      -moz-transition: 0.35s ease-in-out all;
      -ms-transition: 0.35s ease-in-out all;
      -o-transition: 0.35s ease-in-out all;
      transition: 0.35s ease-in-out all;
    }
  }
}
</style>
