export default class Aplicacion {
  constructor() {
    this.copyright = new String();
    this.projects = new Array();
    this.getProject = function (cod) {
      return this.projects.find((projectEl) => projectEl.cod == cod);
    };
    this.clients = new Array();
    this.services = new Array();
    this.statistics = new Array();
    this.staffs = new Array();
  }
}
