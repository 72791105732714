<template>
  <div class="home">
    <Projects :projects="aplicacion.projects" />
    <Statistics :statistics="aplicacion.statistics" />
    <Services :services="aplicacion.services" />
    <Clients :clients="aplicacion.clients" />
  </div>
</template>

<script>
import Projects from "@/components/Projects.vue";
import Statistics from "@/components/Statistics.vue";
import Services from "@/components/Services.vue";
import Clients from "@/components/Clients.vue";

export default {
  name: "Home",
  props: {
    aplicacion: Object,
  },
  components: {
    Projects,
    Statistics,
    Services,
    Clients,
  },
};
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 100px;
  padding: 100px 0px;
}
</style>
