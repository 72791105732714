<template>
  <div class="projects">
    <div class="projects__content">
      <div class="projects__content__menu">
        <div class="projects__content__menu__info">
          <Transition name="fade" mode="out-in">
            <div
              data-aos="fade-up"
              data-aos-delay="0"
              :key="project.name"
              class="projects__content__menu__info__title"
            >
              <span class="projects__content__menu__info__title__name">
                {{ project.name }}
              </span>
              <span class="projects__content__menu__info__title__stars">
                {{ project.stars }}
              </span>
            </div>
          </Transition>
          <Transition name="fade" mode="out-in">
            <span
              data-aos="fade-up"
              data-aos-delay="25"
              :key="project.location"
              class="projects__content__menu__info__location"
            >
              {{ project.location }}
            </span>
          </Transition>
          <Transition name="fade" mode="out-in">
            <span
              data-aos="fade-up"
              data-aos-delay="50"
              :key="project.spaces"
              class="projects__content__menu__info__spaces"
            >
              {{ project.spaces }}
            </span>
          </Transition>
        </div>
        <div class="projects__content__menu__options">
          <router-link
            data-aos="fade-up"
            data-aos-delay="75"
            :to="{ name: 'Projects', params: { project: project.cod } }"
            class="projects__content__menu__options__link"
          >
            <Transition name="fade" mode="out-in">
              <span
                :key="$store.getters.getLanguage"
                class="projects__content__menu__options__link__text"
              >
                {{ getLiteral("ver-proyecto", $store.getters.getLanguage) }}
              </span>
            </Transition>
          </router-link>
          <div class="projects__content__menu__options__controls">
            <span
              data-aos="fade-right"
              data-aos-delay="75"
              class="projects__content__menu__options__controls__item projects__content__menu__options__controls__item--subtract"
              @click="setIndex('-')"
            >
              <img
                :src="requireAsset('icons/dropdown.svg')"
                alt=""
                class="projects__content__menu__options__controls__item__image"
              />
            </span>
            <span
              data-aos="fade-left"
              data-aos-delay="75"
              class="projects__content__menu__options__controls__item projects__content__menu__options__controls__item--add"
              @click="setIndex('+')"
            >
              <img
                :src="requireAsset('icons/dropdown.svg')"
                alt=""
                class="projects__content__menu__options__controls__item__image"
              />
            </span>
          </div>
        </div>
      </div>
      <Transition name="fade" mode="out-in">
        <div :key="index" class="projects__content__miniature skeleton">
          <div class="projects__content__miniature__loader">
            <img
              loading="lazy"
              :src="requireAsset('spinner.svg')"
              alt=""
              class="skeleton__loader"
            />
          </div>
          <img
            loading="lazy"
            @load="resolveAsset($event)"
            :src="requireAsset(project.getPoster('hd'))"
            alt=""
            class="projects__content__miniature__image"
          />
        </div>
      </Transition>
    </div>
    <div class="projects__projects">
      <div
        v-for="(project, projectIndex) in filteredProjects"
        :key="projectIndex"
        class="projects__projects__item"
        :class="{
          active: projectIndex == index,
        }"
        @click="index = projectIndex"
      >
        <div data-aos="fade-up" :data-aos-delay="projectIndex * 50">
          <div class="projects__projects__item__miniature skeleton">
            <img
              loading="lazy"
              :src="requireAsset('spinner.svg')"
              alt=""
              class="skeleton__loader"
            />
            <img
              @load="resolveAsset($event)"
              :src="requireAsset(project.getPoster('ld'))"
              alt=""
              class="projects__projects__item__miniature__image"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Projects",
  props: {
    projects: Array,
  },
  data() {
    return {
      index: 0,
    };
  },
  watch: {
    index() {
      this.scrollToActiveItem();
    },
  },
  methods: {
    setIndex(operation) {
      if (operation === "+") {
        this.index = (this.index + 1) % this.filteredProjects.length;
      } else if (operation === "-") {
        this.index =
          (this.index - 1 + this.filteredProjects.length) %
          this.filteredProjects.length;
      }
    },
    scrollToActiveItem() {
      this.$nextTick(() => {
        const projectsContainer = this.$el.querySelector(".projects__projects");
        const activeItem = projectsContainer.querySelector(".active");

        if (activeItem) {
          projectsContainer.scrollLeft =
            activeItem.offsetLeft -
            projectsContainer.offsetLeft -
            projectsContainer.clientWidth / 2 +
            activeItem.clientWidth / 2;
        }
      });
    },
  },
  computed: {
    filteredProjects() {
      return this.projects.filter(
        (projectEl) => projectEl.visibility === "high"
      );
    },
    project() {
      return this.filteredProjects[this.index];
    },
  },
};
</script>

<style lang="scss" scoped>
.projects {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  gap: 15px;
  &__content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(calc(100dvh - $header-height - 100px) / 1.5);
    min-height: 400px;
    max-height: 600px;
    &__menu {
      position: absolute;
      left: 0;
      z-index: 500;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 35%;
      min-width: 350px;
      height: 100%;
      background-color: rgb(0, 0, 0, 0.65);
      border-radius: 5px 0px 0px 5px;
      padding: 20px;
      gap: 50px;
      &__info {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: auto;
        &__title {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
          &__name {
            text-align: center;
            color: white;
            font-size: 30px;
            font-weight: 400;
            text-transform: uppercase;
          }
          &__stars {
            color: white;
            letter-spacing: 5px;
            font-size: 30px;
            font-weight: 400;
            text-transform: uppercase;
          }
        }
        &__location {
          text-align: center;
          margin-top: 2.5px;
          color: white;
          font-size: 18px;
          font-weight: 500;
          text-transform: uppercase;
        }
        &__text {
          text-align: center;
          margin-top: 15px;
          color: black;
          font-size: 15px;
          font-weight: 300;
        }
        &__spaces {
          text-align: center;
          margin-top: 7.5px;
          color: white;
          font-size: 14px;
          line-height: 18px;
          font-weight: 500;
          text-transform: uppercase;
        }
      }
      &__options {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: auto;
        gap: 25px;
        &__link {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          font-size: 14px;
          font-weight: 500;
          width: auto;
          height: 25px;
          padding: 0px 15px;
          border-radius: 5px;
          background-color: $primary-color;
          text-decoration: none;
          -webkit-transition: 0.35s ease-in-out background-color;
          -moz-transition: 0.35s ease-in-out background-color;
          -ms-transition: 0.35s ease-in-out background-color;
          -o-transition: 0.35s ease-in-out background-color;
          transition: 0.35s ease-in-out background-color;
          @media (hover: hover) {
            &:hover {
              background-color: rgb($primary-color, 0.65);
              -webkit-transition: 0.35s ease-in-out background-color;
              -moz-transition: 0.35s ease-in-out background-color;
              -ms-transition: 0.35s ease-in-out background-color;
              -o-transition: 0.35s ease-in-out background-color;
              transition: 0.35s ease-in-out background-color;
            }
          }
        }
        &__controls {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          gap: 20px;
          &__item {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            font-size: 14px;
            font-weight: 500;
            width: 30px;
            height: 30px;
            border-radius: 5px;
            background-color: $primary-color;
            text-decoration: none;
            -webkit-transition: 0.35s ease-in-out background-color;
            -moz-transition: 0.35s ease-in-out background-color;
            -ms-transition: 0.35s ease-in-out background-color;
            -o-transition: 0.35s ease-in-out background-color;
            transition: 0.35s ease-in-out background-color;
            &__image {
              width: 20px;
              height: 20px;
              object-fit: contain;
            }
            &--subtract {
              & .projects__content__menu__options__controls__item__image {
                transform: rotate(90deg);
              }
            }
            &--add {
              & .projects__content__menu__options__controls__item__image {
                transform: rotate(-90deg);
              }
            }
            @media (hover: hover) {
              &:hover {
                background-color: rgb($primary-color, 0.65);
                -webkit-transition: 0.35s ease-in-out background-color;
                -moz-transition: 0.35s ease-in-out background-color;
                -ms-transition: 0.35s ease-in-out background-color;
                -o-transition: 0.35s ease-in-out background-color;
                transition: 0.35s ease-in-out background-color;
              }
            }
          }
        }
      }
    }
    &__miniature {
      position: relative;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 5px;
      -webkit-transition: 0.35s ease-in-out all;
      -moz-transition: 0.35s ease-in-out all;
      -ms-transition: 0.35s ease-in-out all;
      -o-transition: 0.35s ease-in-out all;
      transition: 0.35s ease-in-out all;
      &__loader {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        height: 100%;
        width: calc(100% - 35%);
        border-radius: 0px 5px 5px 0px;
      }
      &__image {
        opacity: 1;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
        -webkit-transition: 0.35s ease-in-out all;
        -moz-transition: 0.35s ease-in-out all;
        -ms-transition: 0.35s ease-in-out all;
        -o-transition: 0.35s ease-in-out all;
        transition: 0.35s ease-in-out all;
      }
    }
  }
  &__projects {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: auto;
    gap: 30px;
    overflow: hidden;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    &__item {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      &__miniature {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 150px;
        height: 50px;
        border-radius: 5px;
        border: 2px solid transparent;
        padding: 0px;
        -webkit-transition: 0.35s ease-in-out border, 0.35s ease-in-out padding;
        -moz-transition: 0.35s ease-in-out border, 0.35s ease-in-out padding;
        -ms-transition: 0.35s ease-in-out border, 0.35s ease-in-out padding;
        -o-transition: 0.35s ease-in-out border, 0.35s ease-in-out padding;
        transition: 0.35s ease-in-out border, 0.35s ease-in-out padding;
        &__image {
          filter: brightness(0.35);
          width: 100%;
          height: 100%;
          border-radius: 5px;
          object-fit: cover;
          -webkit-transition: 0.35s ease-in-out filter;
          -moz-transition: 0.35s ease-in-out filter;
          -ms-transition: 0.35s ease-in-out filter;
          -o-transition: 0.35s ease-in-out filter;
          transition: 0.35s ease-in-out filter;
        }
      }
      @media (hover: hover) {
        &:hover {
          & .projects__projects__item {
            &__miniature {
              &__image {
                filter: brightness(0.65);
                -webkit-transition: 0.35s ease-in-out filter;
                -moz-transition: 0.35s ease-in-out filter;
                -ms-transition: 0.35s ease-in-out filter;
                -o-transition: 0.35s ease-in-out filter;
                transition: 0.35s ease-in-out filter;
              }
            }
          }
        }
      }
      &.active {
        & .projects__projects__item {
          &__miniature {
            border: 2px solid $primary-color;
            padding: 5px;
            -webkit-transition: 0.35s ease-in-out border,
              0.35s ease-in-out padding;
            -moz-transition: 0.35s ease-in-out border, 0.35s ease-in-out padding;
            -ms-transition: 0.35s ease-in-out border, 0.35s ease-in-out padding;
            -o-transition: 0.35s ease-in-out border, 0.35s ease-in-out padding;
            transition: 0.35s ease-in-out border, 0.35s ease-in-out padding;
            &__image {
              filter: brightness(1);
              -webkit-transition: 0.35s ease-in-out filter;
              -moz-transition: 0.35s ease-in-out filter;
              -ms-transition: 0.35s ease-in-out filter;
              -o-transition: 0.35s ease-in-out filter;
              transition: 0.35s ease-in-out filter;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1000px) {
    &__content {
      &__menu {
        width: 100%;
      }
    }
  }
}
</style>
