import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    theme: new String(),
    language: new String(),
    languages: new Array(),
  },
  getters: {
    getTheme(state) {
      return state.theme;
    },
    getLanguage(state) {
      return state.language;
    },
    getLanguages(state) {
      return state.languages;
    },
  },
  mutations: {
    setTheme(state, theme) {
      state.theme = theme;
      localStorage.setItem("mueblescercos_theme", theme);
    },
    setLanguage(state, language) {
      state.language = language;
      localStorage.setItem("mueblescercos_language", language);
    },
    setLanguages(state, languages) {
      state.languages = languages;
    },
  },
  actions: {},
  modules: {},
});
