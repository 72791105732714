<template>
  <div class="services">
    <div
      v-for="(service, serviceIndex) in services"
      :key="serviceIndex"
      class="services__item"
      data-aos="fade-up"
      :data-aos-delay="serviceIndex * 50"
    >
      <img
        loading="lazy"
        :src="requireAsset(service.miniature)"
        alt=""
        class="services__item__image"
      />
      <Transition name="fade" mode="out-in">
        <span :key="service.name" class="services__item__name">
          {{ service.name }}
        </span>
      </Transition>
      <Transition name="fade" mode="out-in">
        <span :key="service.text" class="services__item__text"
          >{{ service.text }}
        </span>
      </Transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "Services",
  props: {
    services: Array,
  },
};
</script>

<style lang="scss" scoped>
.services {
  display: grid;
  grid-template-columns: repeat(4, minmax(auto, 1fr));
  width: 100%;
  height: 100%;
  gap: 30px;
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 2px solid $primary-color;
    border-radius: 5px;
    gap: 15px;
    padding: 15px;
    &__image {
      width: 55px;
      height: 55px;
      object-fit: contain;
    }
    &__name {
      color: $primary-color;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
    }
    &__text {
      color: black;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      width: 75%;
      -webkit-transition: 0.35s ease-in-out all;
      -moz-transition: 0.35s ease-in-out all;
      -ms-transition: 0.35s ease-in-out all;
      -o-transition: 0.35s ease-in-out all;
      transition: 0.35s ease-in-out all;
    }
  }
  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(3, minmax(auto, 1fr));
    @media screen and (max-width: 700px) {
      grid-template-columns: repeat(2, minmax(auto, 1fr));
      @media screen and (max-width: 500px) {
        grid-template-columns: repeat(1, minmax(auto, 1fr));
      }
    }
  }
}
</style>
