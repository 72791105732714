var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('div',{staticClass:"header__top"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"header__top__languages"},_vm._l((_vm.$store.getters.getLanguages),function(language,languageIndex){return _c('span',{key:languageIndex,staticClass:"header__top__languages__item",class:{
            active: language == _vm.$store.getters.getLanguage,
          },on:{"click":function($event){return _vm.$store.commit('setLanguage', language)}}},[_vm._v(" "+_vm._s(language)+" ")])}),0),_c('div',{staticClass:"header__top__options"},[_c('Transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('img',{key:_vm.$store.getters.getTheme,staticClass:"header__top__options__icon",attrs:{"src":_vm.requireAsset(
                'icons/' +
                  (_vm.$store.getters.getTheme === 'dark' ? 'sun' : 'moon') +
                  '.svg'
              ),"alt":""},on:{"click":function($event){return _vm.$store.commit(
                'setTheme',
                _vm.$store.getters.getTheme === 'dark' ? 'light' : 'dark'
              )}}})])],1)])]),_c('div',{staticClass:"header__bottom"},[_c('div',{staticClass:"wrapper"},[_c('Transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('div',{key:_vm.$store.getters.getLanguage,staticClass:"header__bottom__items"},[_c('router-link',{staticClass:"header__bottom__items__link",attrs:{"to":{ name: 'Projects' },"exact-active-class":"active"}},[_vm._v(_vm._s(_vm.getLiteral("projects", _vm.$store.getters.getLanguage)))]),_c('router-link',{staticClass:"header__bottom__items__miniature",attrs:{"to":{ name: 'Home' }}},[_c('img',{staticClass:"header__bottom__items__miniature__image header__bottom__items__miniature__image--logo",attrs:{"loading":"lazy","src":_vm.requireAsset('logo.webp'),"alt":""}}),_c('img',{staticClass:"header__bottom__items__miniature__image header__bottom__items__miniature__image--name",attrs:{"loading":"lazy","src":_vm.requireAsset('name.webp'),"alt":""}})]),_c('router-link',{staticClass:"header__bottom__items__link",attrs:{"to":{ name: 'About' },"exact-active-class":"active"}},[_vm._v(_vm._s(_vm.getLiteral("about", _vm.$store.getters.getLanguage)))])],1)])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }