<template>
  <div class="header">
    <div class="header__top">
      <div class="wrapper">
        <div class="header__top__languages">
          <span
            v-for="(language, languageIndex) in $store.getters.getLanguages"
            :key="languageIndex"
            class="header__top__languages__item"
            :class="{
              active: language == $store.getters.getLanguage,
            }"
            @click="$store.commit('setLanguage', language)"
          >
            {{ language }}
          </span>
        </div>
        <div class="header__top__options">
          <Transition name="fade" mode="out-in">
            <img
              :key="$store.getters.getTheme"
              :src="
                requireAsset(
                  'icons/' +
                    ($store.getters.getTheme === 'dark' ? 'sun' : 'moon') +
                    '.svg'
                )
              "
              alt=""
              class="header__top__options__icon"
              @click="
                $store.commit(
                  'setTheme',
                  $store.getters.getTheme === 'dark' ? 'light' : 'dark'
                )
              "
            />
          </Transition>
        </div>
      </div>
    </div>
    <div class="header__bottom">
      <div class="wrapper">
        <Transition name="fade" mode="out-in">
          <div :key="$store.getters.getLanguage" class="header__bottom__items">
            <router-link
              :to="{ name: 'Projects' }"
              class="header__bottom__items__link"
              exact-active-class="active"
              >{{
                getLiteral("projects", $store.getters.getLanguage)
              }}</router-link
            >
            <router-link
              :to="{ name: 'Home' }"
              class="header__bottom__items__miniature"
            >
              <img
                loading="lazy"
                :src="requireAsset('logo.webp')"
                alt=""
                class="header__bottom__items__miniature__image header__bottom__items__miniature__image--logo"
              />
              <img
                loading="lazy"
                :src="requireAsset('name.webp')"
                alt=""
                class="header__bottom__items__miniature__image header__bottom__items__miniature__image--name"
              />
            </router-link>
            <router-link
              :to="{ name: 'About' }"
              class="header__bottom__items__link"
              exact-active-class="active"
              >{{
                getLiteral("about", $store.getters.getLanguage)
              }}</router-link
            >
          </div>
        </Transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
};
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: $header-height;
  z-index: 1000;
  &__top {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 25%;
    background-color: $primary-color;
    &__languages {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 100%;
      gap: 15px;
      &__item {
        cursor: pointer;
        opacity: 0.35;
        color: white;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        -webkit-transition: 0.35s ease-in-out opacity;
        -moz-transition: 0.35s ease-in-out opacity;
        -ms-transition: 0.35s ease-in-out opacity;
        -o-transition: 0.35s ease-in-out opacity;
        transition: 0.35s ease-in-out opacity;
        &.active {
          opacity: 1;
          -webkit-transition: 0.35s ease-in-out opacity;
          -moz-transition: 0.35s ease-in-out opacity;
          -ms-transition: 0.35s ease-in-out opacity;
          -o-transition: 0.35s ease-in-out opacity;
          transition: 0.35s ease-in-out opacity;
        }
        @media (hover: hover) {
          &:not(.active):hover {
            opacity: 0.65;
            -webkit-transition: 0.35s ease-in-out opacity;
            -moz-transition: 0.35s ease-in-out opacity;
            -ms-transition: 0.35s ease-in-out opacity;
            -o-transition: 0.35s ease-in-out opacity;
            transition: 0.35s ease-in-out opacity;
          }
        }
      }
    }
    &__options {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      height: 100%;
      gap: 15px;
      &__icon {
        cursor: pointer;
        width: 20px;
        height: 20px;
        object-fit: contain;
      }
    }
  }
  &__bottom {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 75%;
    background-color: rgba(0, 0, 0, 0.65);
    &__items {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      width: 100%;
      height: 100%;
      gap: 15px;
      &__link {
        opacity: 1;
        color: white;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
        white-space: nowrap;
        line-height: 25px;
        border-bottom: 5px solid transparent;
        -webkit-transition: 0.35s ease-in-out border-bottom,
          0.35s ease-in-out opacity;
        -moz-transition: 0.35s ease-in-out border-bottom,
          0.35s ease-in-out opacity;
        -ms-transition: 0.35s ease-in-out border-bottom,
          0.35s ease-in-out opacity;
        -o-transition: 0.35s ease-in-out border-bottom,
          0.35s ease-in-out opacity;
        transition: 0.35s ease-in-out border-bottom, 0.35s ease-in-out opacity;
        &.active {
          border-bottom: 5px solid $primary-color;
          -webkit-transition: 0.35s ease-in-out border-bottom,
            0.35s ease-in-out opacity;
          -moz-transition: 0.35s ease-in-out border-bottom,
            0.35s ease-in-out opacity;
          -ms-transition: 0.35s ease-in-out border-bottom,
            0.35s ease-in-out opacity;
          -o-transition: 0.35s ease-in-out border-bottom,
            0.35s ease-in-out opacity;
          transition: 0.35s ease-in-out border-bottom, 0.35s ease-in-out opacity;
        }
        @media (hover: hover) {
          &:not(.active):hover {
            opacity: 0.35;
            -webkit-transition: 0.35s ease-in-out border-bottom,
              0.35s ease-in-out opacity;
            -moz-transition: 0.35s ease-in-out border-bottom,
              0.35s ease-in-out opacity;
            -ms-transition: 0.35s ease-in-out border-bottom,
              0.35s ease-in-out opacity;
            -o-transition: 0.35s ease-in-out border-bottom,
              0.35s ease-in-out opacity;
            transition: 0.35s ease-in-out border-bottom,
              0.35s ease-in-out opacity;
          }
        }
      }
      &__miniature {
        opacity: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 70px;
        height: 70px;
        gap: 2.5px;
        -webkit-transition: 0.35s ease-in-out opacity;
        -moz-transition: 0.35s ease-in-out opacity;
        -ms-transition: 0.35s ease-in-out opacity;
        -o-transition: 0.35s ease-in-out opacity;
        transition: 0.35s ease-in-out opacity;
        &__image {
          width: 100%;
          height: 100%;
          object-fit: contain;
          &--logo {
            width: 40px;
            height: 40px;
          }
          &--name {
            height: auto;
            filter: invert(100%);
          }
        }
        @media (hover: hover) {
          &:not(.active):hover {
            opacity: 0.35;
            -webkit-transition: 0.35s ease-in-out opacity;
            -moz-transition: 0.35s ease-in-out opacity;
            -ms-transition: 0.35s ease-in-out opacity;
            -o-transition: 0.35s ease-in-out opacity;
            transition: 0.35s ease-in-out opacity;
          }
        }
      }
    }
  }
}
</style>
