<template>
  <div
    class="goTo"
    :class="{
      hidden: scrollTop == 0,
      footerSpace: isAtBottom,
    }"
    @click="scrollToTop"
  >
    <img
      loading="lazy"
      :src="requireAsset('icons/dropdown.svg')"
      alt=""
      class="goTo__image"
    />
  </div>
</template>

<script>
export default {
  name: "GoTo",
  data() {
    return {
      scrollTop: 0,
    };
  },
  created() {
    window.addEventListener("scroll", this.setScrollTop);
  },
  destroyed() {
    window.removeEventListener("scroll", this.setScrollTop);
  },
  computed: {
    isAtBottom() {
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;
      return this.scrollTop + clientHeight >= scrollHeight - 10;
    },
  },
  methods: {
    setScrollTop() {
      this.scrollTop = window.scrollY || document.documentElement.scrollTop;
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss" scoped>
.goTo {
  opacity: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 2000;
  bottom: 15px;
  right: 10px;
  width: 30px;
  height: 30px;
  background-color: $primary-color;
  border-radius: 5px;
  -webkit-transition: 0.35s ease-in-out background-color,
    0.35s ease-in-out opacity, 0.35s ease-in-out bottom;
  -moz-transition: 0.35s ease-in-out background-color, 0.35s ease-in-out opacity,
    0.35s ease-in-out bottom;
  -ms-transition: 0.35s ease-in-out background-color, 0.35s ease-in-out opacity,
    0.35s ease-in-out bottom;
  -o-transition: 0.35s ease-in-out background-color, 0.35s ease-in-out opacity,
    0.35s ease-in-out bottom;
  transition: 0.35s ease-in-out background-color, 0.35s ease-in-out opacity,
    0.35s ease-in-out bottom;
  @media (hover: hover) {
    &:hover {
      background-color: rgb($primary-color, 0.65);
      -webkit-transition: 0.35s ease-in-out background-color,
        0.35s ease-in-out opacity, 0.35s ease-in-out bottom;
      -moz-transition: 0.35s ease-in-out background-color,
        0.35s ease-in-out opacity, 0.35s ease-in-out bottom;
      -ms-transition: 0.35s ease-in-out background-color,
        0.35s ease-in-out opacity, 0.35s ease-in-out bottom;
      -o-transition: 0.35s ease-in-out background-color,
        0.35s ease-in-out opacity, 0.35s ease-in-out bottom;
      transition: 0.35s ease-in-out background-color, 0.35s ease-in-out opacity,
        0.35s ease-in-out bottom;
    }
  }
  &__image {
    width: 20px;
    height: 20px;
    object-fit: contain;
    transform: rotate(180deg);
  }
  &.hidden {
    opacity: 0;
    pointer-events: none;
    -webkit-transition: 0.35s ease-in-out background-color,
      0.35s ease-in-out opacity, 0.35s ease-in-out bottom;
    -moz-transition: 0.35s ease-in-out background-color,
      0.35s ease-in-out opacity, 0.35s ease-in-out bottom;
    -ms-transition: 0.35s ease-in-out background-color,
      0.35s ease-in-out opacity, 0.35s ease-in-out bottom;
    -o-transition: 0.35s ease-in-out background-color, 0.35s ease-in-out opacity,
      0.35s ease-in-out bottom;
    transition: 0.35s ease-in-out background-color, 0.35s ease-in-out opacity,
      0.35s ease-in-out bottom;
  }
  &.footerSpace {
    bottom: 165px;
    -webkit-transition: 0.35s ease-in-out background-color,
      0.35s ease-in-out opacity, 0.35s ease-in-out bottom;
    -moz-transition: 0.35s ease-in-out background-color,
      0.35s ease-in-out opacity, 0.35s ease-in-out bottom;
    -ms-transition: 0.35s ease-in-out background-color,
      0.35s ease-in-out opacity, 0.35s ease-in-out bottom;
    -o-transition: 0.35s ease-in-out background-color, 0.35s ease-in-out opacity,
      0.35s ease-in-out bottom;
    transition: 0.35s ease-in-out background-color, 0.35s ease-in-out opacity,
      0.35s ease-in-out bottom;
  }
}
</style>
